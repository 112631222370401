/*eslint-disable */
// import Admin_FaqModel from '../../Model/Admin_Faq'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let AdminFaq = {
  /**
   * AdminFaqList
   */
  async AdminFaqList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "admin_faq_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at admin_faqList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * AdminFaqModuleList
   */
  async AdminFaqModuleList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "admin_faq_module_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at AdminFaqModuleList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * AdminFaqView
   */
  async AdminFaqView (context, admin_faqId) {
    try {      
      let post_data = new FormData();
      post_data.append('faq_id', admin_faqId);
      return await request.curl(context, "admin_faq_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at admin_faqView() and Exception:",err.message)
    }    
  },

  /**
   * admin_faqAdd
   */
  async AdminFaqAdd (context, admin_faqObj) {
    try{
      let post_data = new FormData();
    
      for (let key in admin_faqObj) {
        post_data.append(key, admin_faqObj[key]);
      }

      return await request.curl(context, "admin_faq_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_faq_add() and Exception:',err.message)
    }
  },

  /**
   * AdminFaqEdit
   */
  async AdminFaqEdit (context, admin_faqObj) {
    try{
      let post_data = new FormData();
    
      for (let key in admin_faqObj) {
        post_data.append(key, admin_faqObj[key]);
      }

      return await request.curl(context, "admin_faq_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_faqEdit() and Exception:',err.message)
    }
  },

  /**
   * admin_faqDelete
   */
  async AdminFaqDelete (context, admin_faqId) {
    try{
      let post_data = new FormData();
      
      post_data.append('faq_id', admin_faqId);

      return await request.curl(context, "admin_faq_delete", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch(err){
      console.log('Exception occurred at admin_faqDelete() and Exception:',err.message)
    }
  },

   /**
   * admin_faq_publish_unpublish
   */
   async admin_faq_publish_unpublish (context, Faq) {
    try{
      let post_data = new FormData();
      post_data.append('faq_id', Faq.faq_id);
      post_data.append('deleted',Faq.deleted);
      return await request.curl(context, "admin_faq_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_faq_publish_unpublish() and Exception:',err.message)
    }
  },
}

export {
  AdminFaq
}
